<template>
  <div id="rebateTransfer">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <h2>{{ $t('transfer.REBATE_TRANSFER_REQUEST') }}</h2>
        <el-row class="form_box d-flex flex-wrap align-content-center">
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-card class="me-0 me-lg-5 mb-5 mb-lg-0">
              <el-form :model="transferForm" :rules="rules" label-position="top" ref="transferForm">
                <ul class="mb-3">
                  <li>
                    <span class="special_title mb-2">{{ $t('common.keys.FROM') }}</span>
                    <el-form-item :label="$t('common.keys.rebateAccount')" prop="rebateAccount">
                      <el-select
                        v-model="transferForm.rebateAccount"
                        @change="rebateAccountChange"
                        data-testid="rebateAccount"
                      >
                        <el-option
                          v-for="item in rebates"
                          :key="item.login"
                          :label="item.login + ' - ' + item.balance + ' ' + item.currency"
                          :value="item.login"
                          :data-testid="item.login"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </li>
                  <li>
                    <span class="special_title mb-2">{{ $t('common.keys.TO') }}</span>
                    <el-form-item :label="$t('transfer.account')" prop="tradingAccount">
                      <el-select v-model="transferForm.tradingAccount" data-testid="tradingAccount">
                        <!-- @change="accountToChange" -->
                        <el-option
                          v-for="item in tradingAccountList"
                          :key="item.login"
                          :label="item.login | accountListFormatter(item, transferTranslationObj)"
                          :value="item.login"
                          :data-testid="item.login"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="mb-3 flex">
                  <li>
                    <el-form-item :label="$t('common.keys.amount')" prop="amount">
                      <span slot="label">
                        <span
                          v-if="
                            !this.seconedSelectedAccountCurrency ||
                              this.selectedRebateAccountCurrency === this.seconedSelectedAccountCurrency
                          "
                          >{{ $t('common.keys.amount') }}</span
                        >
                        <span v-else>
                          {{ $t('common.keys.amountOut') }}
                          <span style="color: #e35728">{{ transferForm.rebateAccount }}</span>
                        </span>
                      </span>
                      <numeric-input
                        class="transfer-amount"
                        ref="numberInput"
                        v-model="transferForm.amount"
                        :showCurrency="true"
                        :showCurrencySymbol="selectedRebateAccountCurrency | currencySymbol"
                        :currency="selectedRebateAccountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                  <li
                    class="ml-20"
                    v-if="
                      this.seconedSelectedAccountCurrency &&
                        this.selectedRebateAccountCurrency !== this.seconedSelectedAccountCurrency
                    "
                  >
                    <el-form-item prop="amount">
                      <span slot="label">
                        <span>{{ $t('common.keys.amountIn') }}</span>
                        <span style="color: #e35728">{{ transferForm.tradingAccount }}</span>
                      </span>
                      <el-input :disabled="true" class="transfer-amount disabled_style" v-model="amountTo"></el-input>
                    </el-form-item>
                  </li>
                </ul>
                <el-button @click="submitForm('transferForm')" data-testid="submit">
                  {{ $t('common.keys.SUBMIT') }}
                </el-button>
              </el-form>
            </el-card></el-col
          ></el-row
        >
      </div>
    </div>
  </div>
</template>

<script>
import { integerOrMaxTwoDecimalNumbersCreator } from '@/util/validators';
import { apiToApplyTransferView, apiApplyTransfer, apiGetFormCurrencyTo, apiGetFormCurrencyData } from '@/resource';
import NumericInput from '@/components/NumericInput';
import mixin from '@/mixins';
import blackList from '@/mixins/blackList';
import floatObj from '@/util/floatObj';

export default {
  components: { NumericInput },
  mixins: [mixin, blackList],
  data() {
    return {
      rate: 0,
      amountTo: '', //转换汇率金额，只做展示
      currencyDataList: [],
      //对话框
      accountList: [],
      tradingAccountList: [],
      rebates: [],
      transferForm: {
        rebateAccount: '',
        amount: '',
        tradingAccount: ''
      },
      selectedRebateAccountCurrency: '',
      availableBalance: '0',
      seconedSelectedAccountCurrency: '',
      seconedBalance: '',
      transferTranslationObj: {
        1: this.$t('transfer.tradingAccount'),
        3: this.$t('transfer.rebateAccount')
      },
      rules: {
        rebateAccount: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            message: this.$t('transfer.PleaseEnterTransferInformation'),

            trigger: 'blur'
          },
          {
            validator: integerOrMaxTwoDecimalNumbersCreator(this.$t('withdraw.CannotUseMoreThanTwoDecimalValues')),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (value == 0) {
                callback(new Error(this.$t('withdraw.Itcannotbe0')));
              } else if (value > this.availableBalance) {
                callback(new Error(this.$t('transfer.transferAmountIsGreaterThanAvailableBalance')));
              } else if (value % 1 !== 0 && this.selectedRebateAccountCurrency === 'USC') {
                callback(new Error(this.$t('withdraw.integer')));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        tradingAccount: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'change'
          }
        ]
      },
      userId: this.$store.state.common.CUID,
      prevRoute: null
    };
  },
  //get previous path
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  filters: {
    accountListFormatter: function(value, item, transferTranslationObj) {
      if (value === undefined || value === null) {
        return '';
      } else {
        const currency = item.currency || ' ';
        const accountType =
          item.accountDealType === 1 || item.accountDealType === 3 ? transferTranslationObj[item.accountDealType] : '';
        value = item.login + ' - ' + currency + (accountType ? ' (' + accountType + ')' : '');
      }
      return value;
    }
  },
  watch: {
    selectedRebateAccountCurrency() {
      if (this.transferForm.amount !== 0) this.$refs.transferForm.validateField('amount');
    },
    'transferForm.rebateAccount'(value) {
      let list = this.rebates.concat(this.accountList);
      this.transferForm.tradingAccount = '';
      this.transferForm.rebateAccount = value;
      const [arr] = list.filter(item => {
        return item.login === value;
      });
      this.selectedRebateAccountCurrency = arr.currency;

      this.GetFormCurrencyData(this.selectedRebateAccountCurrency);
    },
    'transferForm.tradingAccount'(value) {
      this.transferForm.tradingAccount = value;
      const [arr] = this.tradingAccountList.filter(item => {
        return item.login === value;
      });
      this.seconedSelectedAccountCurrency = arr.currency;
      this.queryCurrencyTo();
    },
    'transferForm.amount'(value) {
      this.amountTo =
        this.$options.filters.currencySymbol(this.seconedSelectedAccountCurrency) +
        ' ' +
        floatObj.multiply(value, this.rate);
    }
  },
  methods: {
    queryCurrencyTo() {
      let data = {
        formCurrency: this.selectedRebateAccountCurrency,
        toCurrency: this.seconedSelectedAccountCurrency
      };
      apiGetFormCurrencyTo(data)
        .then(res => {
          this.rate = res.data.data;
          this.amountTo =
            this.$options.filters.currencySymbol(this.seconedSelectedAccountCurrency) +
            ' ' +
            floatObj.multiply(this.transferForm.amount, this.rate);
        })
        .catch(err => {
          console.log(err);
        });
    },
    accountToChange(val) {
      const [availableBalance] = this.tradingAccountList.filter(item => {
        return item.login === val;
      });
      if (availableBalance == undefined) {
        return;
      }
      this.seconedBalance = availableBalance.balance;
      this.seconedSelectedAccountCurrency = availableBalance.currency;
      // this.queryCurrencyTo();
    },
    //获取可以转换的币种接口
    GetFormCurrencyData(currency) {
      this.currencyDataList = [];
      apiGetFormCurrencyData(currency)
        .then(res => {
          if (res.data.code === 0) {
            this.currencyDataList = res.data.data;
            this.rebateAccountChange(this.transferForm.rebateAccount);
          }
        })
        .catch(err => {
          this.$message({ message: 'Fetch trading accounts failed.', type: 'error' });
        });
    },
    //form表单提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) return false;
        else if (!this.checkRebateTransferBlacklist()) {
          this.transferErrMsg();
        } else this.applyTransfer();
      });
    },
    getAccountInfo() {
      apiToApplyTransferView({
        qUserId: this.userId
      }).then(resp => {
        if (resp.data.code === 0) {
          const respData = resp.data.data;
          this.accountList = respData.accountList;
          this.rebates = respData.rebates;
          this.rebates.forEach(element => {
            element.balance = element.balance.toFixed(2);
          });
          // 默认选中首页选中的账号
          if (this.rebates && this.rebates.length > 0) {
            this.transferForm.rebateAccount = this.rebates[0].login;
            this.selectedRebateAccountCurrency = this.rebates[0].currency;
            //此处需要添加一个接口，来获取可以切换什么币种
            // this.GetFormCurrencyData(this.selectedRebateAccountCurrency);
          }
          // this.rebateAccountChange(this.transferForm.rebateAccount);
        }
      });
    },
    // 佣金账号改变
    rebateAccountChange(val) {
      const [availableBalance] = this.rebates.filter(item => {
        return item.login === val;
      });
      if (availableBalance == undefined) {
        return;
      }
      this.availableBalance = availableBalance.balance;
      this.selectedRebateAccountCurrency = availableBalance.currency;

      let selectionList = this.rebates.concat(this.accountList).filter(item => {
        return item.login !== val;
      });
      this.tradingAccountList = selectionList.filter(a => {
        return this.currencyDataList.includes(a.currency);
      });
      this.transferForm.tradingAccount = this.tradingAccountList.length > 0 ? this.tradingAccountList[0].login : null;
      this.seconedSelectedAccountCurrency =
        this.tradingAccountList.length > 0 ? this.tradingAccountList[0].currency : null;
      // this.queryCurrencyTo();
    },
    applyTransfer() {
      apiApplyTransfer(
        {
          qUserId: this.userId,
          fromAccount: this.transferForm.rebateAccount,
          toAccount: this.transferForm.tradingAccount,
          amount: this.transferForm.amount,
          transferType:
            this.tradingAccountList.find(item => item.login === this.transferForm.tradingAccount).accountDealType === 1
              ? 3
              : 4
        },
        this.token
      ).then(resp => {
        if (resp.data.code === 0) {
          this.$message.success(this.$t('transfer.yourRebateTransferHasBeenProcessed'));
          this.bus.$emit('updateTransferHistory', true);
          this.$nextTick(function() {
            this.$router.push(this.prevRoute && this.prevRoute.path != '/' ? this.prevRoute.name : 'home');
          });
        }
      });
    },
    async checkRebateTransferBlacklist() {
      if (this.$route.params.passBlacklist) return true;
      else
        await this.getRebateTransferBlacklist().then(resp => {
          return resp;
        });
    }
  },
  mounted() {
    this.getAccountInfo();
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/pages/rebateTransfer.scss';
.ml-20 {
  margin-left: 20px;
}
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.disabled_style {
  /deep/ .el-input__inner {
    color: #b3b3b3 !important;
    background-color: white !important;
  }
}
</style>
