import { apiRebateTransferBlacklist, apiQueryBlacklist } from '@/resource'

export default {
  methods: {
    goToRebateWithdraw() {
      this.getApiQueryBlacklist().then(resp => {
        resp
          ? this.$router.push({ path: '/rebateWithdraw' })
          : this.$message({
              message: this.$t('withdraw.blackList', { supportEmail: this.GLOBAL_CONTACT_EMAIL }),
              type: 'error',
            })
      })
    },
    async getApiQueryBlacklist() {
      return new Promise(resolve => {
        apiQueryBlacklist().then(resp => {
          resolve(resp.data.data)
        })
      })
    },
    goToRebateTransfer() {
      this.getRebateTransferBlacklist().then(resp => {
        resp ? this.$router.push({ name: '/rebateTransfer', params: { passBlacklist: true } }) : this.transferErrMsg()
      })
    },
    async getRebateTransferBlacklist() {
      return new Promise(resolve => {
        apiRebateTransferBlacklist().then(resp => {
          resolve(resp.data.data)
        })
      })
    },
    transferErrMsg() {
      this.$message({
        message: this.$t('transfer.blackList', { supportEmail: this.GLOBAL_CONTACT_EMAIL }),
        type: 'error',
      })
    },
  },
}
